class MLS_Search {
    statesList: Array<string> = [];
    constructor() {
        const searchResultsWrappers = document.getElementsByClassName( 'mls-listings-search-results' ) as HTMLCollection;
        if ( searchResultsWrappers ) {
            // Paging/Sort
            [ ...searchResultsWrappers ].forEach( ( resultsWrapper: Element, index: number ): void => { 
                const wrapper: HTMLElement = resultsWrapper as HTMLElement;
                wrapper.addEventListener( 'click', ( e ) => {
                    //this.pagingHandler 
                    const target: HTMLElement = e.target as HTMLElement;
                    const navButton: HTMLAnchorElement | null = target.closest( '.mls-nav' );
                    const sortList: HTMLAnchorElement | null = target.closest( '.mls-sort' );
                    const sortButton: HTMLButtonElement | null = target.closest( '.mls-sort-button' );
                    const searchResultsWrapper: HTMLElement | null = target.closest( '.mls-listings-search-results' );
                    // Sort
                    if ( sortList && searchResultsWrapper ) {
                        this.sortHandler( e, sortList, searchResultsWrapper );
                    }
                    // Sort Button
                    if ( sortButton && searchResultsWrapper ) {        
                        e.preventDefault();
                        const index: number = Number( sortButton.getAttribute( 'data-index' ) );
                        const ul: HTMLUListElement = document.getElementById( `mls-sort-list-${index}`) as HTMLUListElement;
                        if ( ul.classList.contains( 'open' ) ) {
                            ul.classList.remove( 'open' );
                        } else {
                            ul.classList.add( 'open' );
                        }
                    }

                    // Paging
                    if ( navButton && searchResultsWrapper ) {
                        this.pagingHandler( e, navButton, searchResultsWrapper );
                    }
                } );
            } );
        }
        const searchForms: HTMLCollection = document.getElementsByClassName( 'mls-search-form' ) as HTMLCollection;
        if ( searchForms ) {
            // Search
            [ ...searchForms ].forEach( ( searchForm: Element, index: number ): void => { 
                const form: HTMLFormElement = searchForm as HTMLFormElement;
                form.addEventListener( 'change', ( e: Event ) => this.searchHandler( e ) );
                form.addEventListener( 'submit', ( e: Event ) => e.preventDefault() );
            } );
        }
        const searchWidgets: HTMLCollection = document.getElementsByClassName( 'mls-search-form-widget' ) as HTMLCollection;
        if ( searchWidgets ) {
            // Search
            [ ...searchWidgets ].forEach( ( searchWidget: Element, index: number ): void => { 
                const form: HTMLFormElement = searchWidget as HTMLFormElement;
                form.addEventListener( 'change', this.widgetHandler );
                form.addEventListener( 'submit', ( e: Event ) => e.preventDefault() );
            } );
        }
        const priceFields: HTMLCollection = document.getElementsByClassName( 'mls-price' ) as HTMLCollection;
        if ( priceFields ) {
            // Price
            [ ...priceFields ].forEach( ( priceField: Element, index: number ): void => { 
                const field: HTMLInputElement = priceField as HTMLInputElement;
                field.addEventListener( 'focus', this.priceFocusHandler );
                field.addEventListener( 'blur', this.priceBlurHandler );
            } );
        }
        const locationButtons: HTMLCollection = document.getElementsByClassName( 'mls-location-btn' ) as HTMLCollection;
        if ( locationButtons ) {
            [ ...locationButtons ].forEach( ( locationButton: Element, index: number ): void => { 
                locationButton.addEventListener( 'click', ( e: Event ) => this.searchHandler( e ) );
            } );
        }
    }

    pagingHandler = ( e: MouseEvent, navButton: HTMLAnchorElement, searchResultsWrapper: HTMLElement ): void => {
        e.preventDefault();
        const url: string = navButton.getAttribute( 'href' ) as string;
        this.getResults( url, searchResultsWrapper, true );
    }

    sortHandler = ( e: MouseEvent, sortList: HTMLAnchorElement, searchResultsWrapper: HTMLElement ): void => {
        e.preventDefault();
        const url: string = sortList.getAttribute( 'href' ) as string;
        this.getResults( url, searchResultsWrapper, true );
    }

    searchHandler = async ( e: Event ): Promise<void> => {
        try {
            const field: HTMLElement = e.target as HTMLElement;
            const form: HTMLFormElement = field.closest( 'form' ) as HTMLFormElement;
            const searchResultsWrapper: HTMLElement = form.nextElementSibling as HTMLElement;
            if ( form ) {
                const formData = new FormData( form );
                const dataURL: string = form.getAttribute( 'action' ) as string;
                if ( dataURL ) {
                    const url = new URL( dataURL );
                    const oldParams = new URLSearchParams( url.search ); 
                    const params = new URLSearchParams( oldParams );
                    // Update params with FormData values
                    for ( const [ key, value ] of formData.entries() ) {
                        if ( value.toString() !== '' ) {
                            if ( key === 'location' ) {
                                try {
                                    if ( this.isState( value.toString() ) ) {
                                        params.set( 'State', this.getStateValue( value.toString() ) );
                                    } else if ( this.isZip( value.toString() ) ) {
                                        params.set( 'Zip', value.toString() );
                                    } else {
                                        const location: Record<string, any> = await this.getBounds( value.toString() );
                                        params.set( 'location', location.string );
                                        params.set( 'bounds', [ location.bounds.northeast.lat, location.bounds.northeast.lng, location.bounds.southwest.lat, location.bounds.southwest.lng ].toString() );
                                    }
                                } catch ( error ) {
                                    console.error('Error processing location:', error);
                                    throw error;
                                }
                            } else {
                                params.set( key, value.toString() );
                            }
                        }
                    }
                    // Replace the original search parameters with the merged ones
                    url.search = params.toString(); 
                    const newURL = url.toString();
                    this.getResults( newURL, searchResultsWrapper, false );
                }
            }
        } catch ( error ) {
            console.error( 'Error in searchHandler:', error );
        }
    }

    widgetHandler = async ( e: Event ): Promise<void> => {
        e.preventDefault();
        try {
            const field: HTMLElement = e.target as HTMLElement;
            const form: HTMLFormElement = field.closest( 'form' ) as HTMLFormElement;
            if ( form ) {
                const formData = new FormData( form );
                const dataURL: string = form.getAttribute( 'action' ) as string;
                if ( dataURL ) {
                    const url = new URL( dataURL );
                    const params = new URLSearchParams( url.search ); 
                    // Update params with FormData values
                    for ( const [ key, value ] of formData.entries() ) {
                        if ( value.toString() !== '' ) {
                            if ( key === 'location' ) {
                                try {
                                    if ( this.isState( value.toString() ) ) {
                                        params.set( 'State', this.getStateValue( value.toString() ) );
                                    } else if ( this.isZip( value.toString() ) ) {
                                        console.log('is Zip!');
                                        params.set( 'Zip', value.toString() );
                                    } else {
                                        const location: Record<string, any> = await this.getBounds( value.toString() );
                                        params.set( 'location', location.string );
                                        params.set( 'bounds', [ location.bounds.northeast.lat, location.bounds.northeast.lng, location.bounds.southwest.lat, location.bounds.southwest.lng ].toString() );
                                    }
                                } catch ( error ) {
                                    console.error('Error processing location:', error);
                                    throw error;
                                }
                            } else {
                                params.set( key, value.toString() );
                            }
                        }
                    }
                    // Replace the original search parameters with the merged ones
                    url.search = params.toString(); 
                    const newURL = url.toString();
                    form.setAttribute( 'action', newURL);
                    form.submit();
                }
            }
        } catch ( error ) {
            console.error( 'Error in searchHandler:', error );
        }        
    }

    priceFocusHandler = ( e: Event ): void => {
        const field: HTMLInputElement = e.target as HTMLInputElement;
        const list: Element | null = field.nextElementSibling;
        if ( list ) {
            list.classList.add( 'open' );
            list.addEventListener( 'click', this.setPrice );
        }
    }

    priceBlurHandler = ( e: FocusEvent ): void => {
        const field: HTMLInputElement = e.target as HTMLInputElement;
        const list: Element | null = field.nextElementSibling;
        if ( list && e.relatedTarget && list.contains( e.relatedTarget as Node ) ) {
            return; // Do nothing if a list item was clicked
        }
        if ( list ) {
            list.classList.remove( 'open' );
            list.removeEventListener( 'click', this.setPrice );
        }
    }

    setPrice = ( e: Event ): void => {
        const button: HTMLButtonElement = e.target as HTMLButtonElement;
        const value: string = button.getAttribute( 'data-value' ) as string;
        const fieldID: string | null = button?.getAttribute( 'data-field' );
        if ( fieldID ) {
            const field: HTMLInputElement = document.getElementById( fieldID ) as HTMLInputElement;
            field.value = value;
            const changeEvent = new Event( 'change', { bubbles: true } );
            field.dispatchEvent( changeEvent ); 
            const blurEvent = new Event( 'blur', { bubbles: true } );
            field.dispatchEvent( blurEvent ); 
        }
    }

    getResults = async ( url: string, searchResultsWrapper: HTMLElement, scroll: boolean ): Promise<void> => {
        try {
            searchResultsWrapper.classList.add( 'mls-loading' );
            const index: number = Number( searchResultsWrapper.getAttribute( 'data-index' ) );
            const form: HTMLElement | null = document.getElementById( 'mls-search-form-' + index );
            form?.setAttribute( 'action', url );
	    
	        // Push the new state to history
	        window.history.pushState({ searchURL: url }, '', url);
	
            const resultsID: string = searchResultsWrapper.getAttribute( 'id' ) as string;
            const response: Response = await fetch( url );
            
            if ( ! response.ok ) {
                throw new Error( `HTTP error! Status: ${response.status}` );
            }
            
            // Parse the HTML string into a DOM Document
            const htmlString: string = await response.text();
            const parser: DOMParser = new DOMParser();
            const doc: Document = parser.parseFromString( htmlString, 'text/html' );
            const searchResults: HTMLElement | null = doc.getElementById( resultsID.replace( /[\d]+$/, '1' ) );
            if ( ! searchResults) {
                throw new Error( "No matching search results found in the fetched HTML." );
            }
            searchResultsWrapper.classList.remove( 'mls-loading' );
            searchResultsWrapper.innerHTML = searchResults.innerHTML;

            // Get new price list, in case listing type changed
            const priceLists: HTMLCollection | null = doc.getElementsByClassName( 'mls-price-list' );
            if ( priceLists ) {
                [ ...priceLists ].forEach( ( priceList: Element ) => {
                    const newPrices: string = priceList.innerHTML;
                    const priceListID: string = priceList.getAttribute( 'id' ) as string;
                    const oldPriceList: HTMLElement | null = document.getElementById( priceListID );
                    if ( oldPriceList ) {
                        oldPriceList.innerHTML = newPrices;
                    }
                } );
            }
            // Maybe scroll to results
            if ( scroll ) {
                searchResultsWrapper.scrollIntoView();
            }
        } catch ( error ) {
            console.error( "Error in getResults:", error );
            throw error;
        }
    }


    getBounds = async ( locationString: string ): Promise<Record<string, any>> => {
        const googleURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(locationString)}&key=AIzaSyBhngeqpbUIOlwNQYlRrllEl94NAzAxgvg`;
    
        try {
            const response: Response = await fetch( googleURL );
            if ( ! response.ok ) {
                throw new Error( `Error fetching location details: ${response.statusText}` );
            }
            const json = await response.json();
            // Check if the response has results
            if ( json.status === 'OK' && json.results.length > 0 ) {
                const location = {
                    string: json.results[0].formatted_address,
                    bounds: json.results[0].geometry.bounds ? json.results[0].geometry.bounds : json.results[0].geometry.viewport,
                }
                return location;
            } else {
                throw new Error( `No valid results found for location: ${locationString}` );
            }
        } catch ( error ) {
            console.error( 'Error fetching Google Maps API data:', error );
            throw error;
        }
    }

    setStatesList = (): void => {
        const statesMap: Record<string, string> = this.getStates();
        for ( const state in statesMap ) {
            this.statesList.push( state.toLowerCase() );
            this.statesList.push( statesMap[ state ].toLowerCase() );
        }
    }

    isState = ( location: string ): boolean => {
        if ( ! this.statesList.length ) {
            this.setStatesList();
        }
        return this.statesList.includes( location.toLowerCase() )
    }
    
    getStateValue = ( state: string ): string => {
        if ( state.length === 2 ) {
            return state.toUpperCase();
        } else {
            const states: Record<string, string> = this.getStates();
            for ( const fullName in states ) {
                if ( state === fullName.toLowerCase() ) {
                    return states[ fullName ];
                }
            }
        }
        return state;
    }
    
    isZip = ( value: string ): boolean => {
        const zipRegex: RegExp = /^\d{5}(-\d{4})?$/;
        return zipRegex.test( value.trim() );
    }
    
    getStates = (): Record<string, string> => {
        return {
            'Alabama': 'AL',
            'Alaska': 'AK',
            'Arizona': 'AZ',
            'Arkansas': 'AR',
            'California': 'CA',
            'Colorado': 'CO',
            'Connecticut': 'CT',
            'Delaware': 'DE',
            'District of Columbia': 'DC',
            'Florida': 'FL',
            'Georgia': 'GA',
            'Hawaii': 'HI',
            'Idaho': 'ID',
            'Illinois': 'IL',
            'Indiana': 'IN',
            'Iowa': 'IA',
            'Kansas': 'KS',
            'Kentucky': 'KY',
            'Louisiana': 'LA',
            'Maine': 'ME',
            'Maryland': 'MD',
            'Massachusetts': 'MA',
            'Michigan': 'MI',
            'Minnesota': 'MN',
            'Mississippi': 'MS',
            'Missouri': 'MO',
            'Montana': 'MT',
            'Nebraska': 'NE',
            'Nevada': 'NV',
            'New Hampshire': 'NH',
            'New Jersey': 'NJ',
            'New Mexico': 'NM',
            'New York': 'NY',
            'North Carolina': 'NC',
            'N Carolina': 'NC',
            'North Dakota': 'ND',
            'N Dakota': 'ND',
            'Ohio': 'OH',
            'Oklahoma': 'OK',
            'Oregon': 'OR',
            'Pennsylvania': 'PA',
            'Puerto Rico': 'PR',
            'Rhode Island': 'RI',
            'South Carolina': 'SC',
            'S Carolina': 'SC',
            'South Dakota': 'SD',
            'S Dakota': 'SD',
            'Tennessee': 'TN',
            'Texas': 'TX',
            'Utah': 'UT',
            'Vermont': 'VT',
            'Virginia': 'VA',
            'Virgin Islands': 'VI',
            'Washington': 'WA',
            'West Virginia': 'WV',
            'W Virginia': 'WV',
            'Wisconsin': 'WI',
            'Wyoming': 'WY',
        };
    }
}

document.addEventListener( 'DOMContentLoaded', function() {
    // Initialize paging
    new MLS_Search();
    // Use replaceState for Initial Load
    window.history.replaceState( { searchURL: window.location.href }, '', window.location.href );
} );

window.addEventListener( 'popstate', ( e ) => {
    if ( e.state && e.state.searchURL ) {
        //console.log( "Navigating back to:", e.state.searchURL );
        
        // Find the correct search results wrapper
        const searchResultsWrapper: HTMLElement | null = document.querySelector( '.mls-listings-search-results' );
        if ( searchResultsWrapper ) {
            // Fetch fresh results instead of using cached history
            fetch( e.state.searchURL, { cache: "no-store" } )
                .then( response => {
                    if ( ! response.ok ) throw new Error( `HTTP error! Status: ${response.status}` );
                    return response.text();
                } )
                .then( htmlString => {
                    // Parse response and update the UI
                    const parser = new DOMParser();
                    const doc = parser.parseFromString( htmlString, 'text/html' );
                    const searchResults = doc.querySelector( '.mls-listings-search-results' );

                    if ( searchResults ) {
                        searchResultsWrapper.innerHTML = searchResults.innerHTML;
                    }
                } )
                .catch( error => console.error( "Error handling back navigation:", error ) );
        }
    }
} );